<template>
  <b-form
    @submit="submit"
    action="https://www.eprocessingnetwork.com/cgi-bin/wo/order.pl"
    method="POST"
  >
    <input type="hidden" name="ePNAccount" value="0517892" />
    <input type="hidden" name="ReturnToURL" value="http://www.trueframe.com" />
    <input type="hidden" name="BackgroundColor" value="White" />
    <input type="hidden" name="TextColor" value="Black" />
    <input type="hidden" name="ItemQty" value="1" />
    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
    <!--  these lines if you wish to test in debug mode.                          -->
    <!--  <input type="hidden" name="debug" value=1>                              -->
    <!--  <input type="hidden" name="debugEmail"                                  -->
    <!--  value="mcasciano@acvauctions.com">                                      -->
    <!--  ----------------------------------------------------------------------  -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          label="VIN / Invoice #"
          label-for="ItemDesc"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('ItemDesc')"
          maxlength="40"
          invalid-feedback="This field is required"
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="ItemDesc"
            maxlength="40"
            name="ItemDesc"
            size="20"
            v-model="$v.form.ItemDesc.$model"
            :state="validateState('ItemDesc')"
            placeholder="Enter a VIN or invoice #"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Total Amount"
          label-for="ItemCost"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('ItemCost')"
          invalid-feedback="This field is required"
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="ItemCost"
            maxlength="80"
            name="ItemCost"
            size="20"
            placeholder="Enter a dollar value"
            v-model="$v.form.ItemCost.$model"
            :state="validateState('ItemCost')"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col lg="4" offset-lg="4">
        <b-button
          type="submit"
          variant="green-500"
          size="lg"
          :disabled="isSubmitting"
          class="
            font-weight-bold font-realist-normal
            text-xl text-white text-uppercase
            tracking-wider
            w-100
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <template v-if="isSubmitting">
            <b-spinner small class="mr-2 flex-shrink-0"></b-spinner>
            Loading...
          </template>
          <template v-else>Continue</template>
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mask } from "vue-the-mask";

const isPhone = value =>
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value);

export default {
  name: "OrderForm",
  mixins: [validationMixin],
  directives: { mask },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      isSubmitting: false,
      form: {
        ItemDesc: "",
        ItemCost: ""
      }
    };
  },
  validations: {
    form: {
      ItemDesc: { required },
      ItemCost: { required }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return this.$v.form.$dirty && $dirty ? (!$error ? null : false) : null;
    },
    submit(e) {
      this.isSubmitting = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        e.preventDefault();
        this.isSubmitting = false;
        return;
      }
    },
    resetForm() {
      this.form.ItemDesc = "";
      this.form.ItemCost = "";
    }
  }
};
</script>
